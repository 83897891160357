<template>
  <v-container>
    <masonry :cols="$vuetify.breakpoint.smAndDown ? 1 : 2" :gutter="30">
      <RemoteControl
        v-if="$can('rc.playlist', 'ws_rc') || $can('read', 'Superadmin')"
      />
      <ActiveWebengines
        v-if="$can('read', 'web_engines') || $can('read', 'Superadmin')"
      />
      <ActiveEvents
        v-if="$can('read', 'events') || $can('read', 'Superadmin')"
      />
      <ActivePresets
        v-if="$can('read', 'engine_presets') || $can('read', 'Superadmin')"
      />
    </masonry>
  </v-container>
</template>

<script>
import "@/plugins/vue-masonry-css"

export default {
  components: {
    RemoteControl: () => import("@/components/dashboard/RemoteControl.vue"),
    ActiveWebengines: () =>
      import("@/components/dashboard/ActiveWebengines.vue"),
    ActiveEvents: () => import("@/components/dashboard/ActiveEvents.vue"),
    ActivePresets: () => import("@/components/dashboard/ActivePresets.vue"),
  },
  data: () => ({}),
  methods: {},
  computed: {},
}
</script>

<style lang="sass" scoped>
// .rc-playlists-home
//   width: 100%
// .rc-playlists-home tr
//   cursor: pointer
// .v-data-table__wrapper
//   tbody tr
//     cursor: pointer
</style>
